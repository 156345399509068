<template>
</template>
<script>
    import {login, loginZfb} from "../api/login";
    import Toast from "vant/lib/toast";

    export default {
        name: 'about',
        data() {
            return {
                token: "",
                code: "",
                data: null,
            }
        },
        created() {
            let that = this;
            if (sessionStorage.getItem('token')) {
                let url = window.sessionStorage.getItem("authUrl");
                //跳转
                this.$router.push(url);
                return
            }
            let reg, params, code;
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf('micromessenger') != -1;
            if (!isWeixin) {
                code = this.GetQueryString("auth_code");
                that.code = code;
                if (code) {
                    loginZfb(code).then((res) => {
                        //成功进这个
                        sessionStorage.setItem('avatar', res.data.data.avatar);
                        sessionStorage.setItem('token', res.data.data.token);
                        sessionStorage.setItem('userId', res.data.data.id);
                        sessionStorage.setItem('userInfo', JSON.stringify(res.data.data));
                        sessionStorage.setItem('payType', 2);
                        let url = window.sessionStorage.getItem("authUrl");
                        //跳转
                        this.$router.push(url);
                        // this.$route.push({name:"home"})
                        //删除本地authUrl
                        // sessionStorage.removeItem('authUrl')
                    }).catch(err => { //报错进这个
                        Toast(err);
                    });
                } else {
                    this.$router.push('home');
                }
            } else {
                params = window.location.search;
                reg = /code=(.*)&/;
                code = reg.exec(params)[1].trim();
                that.code = code;
                if (code) {
                    login(code).then((res) => {
                        if (res.data.code == 'SUCCESS') {
                            //成功进这个
                            sessionStorage.setItem('avatar', res.data.data.avatar);
                            sessionStorage.setItem('token', res.data.data.token);
                            sessionStorage.setItem('userId', res.data.data.id);
                            sessionStorage.setItem('openId', res.data.data.openId);
                            sessionStorage.setItem('userInfo', JSON.stringify(res.data.data));
                            sessionStorage.setItem('payType', 1);
                            let url = window.sessionStorage.getItem("authUrl");
                            //跳转
                            this.$router.push(url);
                        } else {
                            alert(res.data.message)
                        }
                        // this.$route.push({name:"home"})
                        //删除本地authUrl
                        // sessionStorage.removeItem('authUrl')
                    }).catch(err => { //报错进这个
                        Toast("报错信息", err);
                    });
                } else {
                    this.$router.push('home');
                }
            }
        },
        methods: {
            GetQueryString(param) { //param为要获取的参数名 注:获取不到是为null
                var currentUrl = window.location.href; //获取当前链接
                var arr = currentUrl.split("?");//分割域名和参数界限
                if (arr.length > 1) {
                    arr = arr[1].split("&");//分割参数
                    for (var i = 0; i < arr.length; i++) {
                        var tem = arr[i].split("="); //分割参数名和参数内容
                        if (tem[0] == param) {
                            return tem[1];
                        }
                    }
                    return null;
                } else {
                    return null;
                }
            },
        }
    }
</script>
